<script>
  import { get } from 'vuex-pathify'
  import SubscribeProgressIndicator from '@/components/subscribe/SubscribeProgressIndicator'

  export default {
    components: {
      SubscribeProgressIndicator,
    },
    computed: {
      ...get('subscribe', ['selectedPlanHandle']),
      ...get('config', ['isCurrentBoxWaitlist']),
      showProgressIndicator() {
        const routesToHide = [
          'AlltruePlusUpgrade',
          'SubscribePlan',
          'SubscribeInterstitial',
          'SubscribeMarket',
          'SubscribeMarketProduct',
          'SubscribeMarketCheckout',
          'SubscribeMarketCheckoutProduct',
          'SubscribeMarketConfirmation',
        ]
        if (this.selectedPlanHandle === 'alltruePlus') {
          routesToHide.push('SubscribeInsiders')
        }
        if (this.selectedPlanHandle !== 'quarterly') {
          routesToHide.push('SubscribeWelcome')
        }
        return !this.isCurrentBoxWaitlist && !routesToHide.includes(this.$route.name)
      },
    },
  }
</script>

<template>
  <div class="overflow-hidden">
    <SubscribeProgressIndicator v-if="showProgressIndicator" />
    <RouterView />
  </div>
</template>
