<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    computed: {
      ...get('subscribe', ['selectedPlanHandle']),
      ...get('account', ['findSubscriptionById']),
      ...get('config', ['currentBox']),
      screen,
      isInSignupView() {
        return this.$route.name === 'SubscribeSignup'
      },
      noQuarterlyChoice() {
        return this.selectedPlanHandle === 'quarterly' && !this.currentBox?.choiceQuarterlyEnabled
      },
      mainSteps() {
        const stepOneLabel = `Select ${this.screen.lg ? 'a ' : ''}Plan`
        const stepThreeLabel = this.noQuarterlyChoice
          ? 'Bonus Perks'
          : `Customize ${this.screen.lg ? 'Your Box' : ''}`
        return [
          {
            position: 1,
            label: stepOneLabel,
            routeNames: ['SubscribeSelectPlan', 'SubscribePlan'],
          },
          { position: 2, label: 'Checkout', routeNames: ['SubscribeSignup'] },
          {
            position: 3,
            label: stepThreeLabel,
          },
        ]
      },
      nestedSteps() {
        const stepOneLabel =
          this.selectedPlanHandle === 'alltruePlus'
            ? this.screen.lg
              ? 'ALLTRUE+ VIP'
              : 'VIP'
            : 'INTRO'
        const stepThreeLabel = this.noQuarterlyChoice ? 'MARKET' : 'CHOICES'
        return [
          {
            position: 1,
            label: stepOneLabel,
            routeNames: ['SubscribeInsiders', 'SubscribeTshirt'],
          },
          {
            position: 2,
            label: stepThreeLabel,
            routeNames: ['CustomizeChoice', 'SubscribeWelcome'],
          },
        ]
      },
      currentMainStep() {
        return this.getStep(this.mainSteps)
      },
      currentNestedStep() {
        const nestedSteps = [
          ...this.nestedSteps,
          {
            position: 3,
            routeNames: ['CustomizeSummary', 'CustomizeComplete'],
          },
        ]
        return this.getStep(nestedSteps)
      },
    },
    methods: {
      getStep(steps) {
        return steps.findIndex((step) => step.routeNames?.includes(this.$route.name)) + 1
      },
    },
  }
</script>

<template>
  <BaseProgressIndicator
    :main-steps="mainSteps"
    :nested-steps="nestedSteps"
    :current-main-step="currentMainStep"
    :current-nested-step="currentNestedStep"
    :show-nested-steps="!isInSignupView"
    @back="$navigate({ name: 'SubscribePlan' })"
  />
</template>
